import { pick, map, compose } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { getCountries } from '@twnel/web-components';
import { USER } from '../namespace';
import { initialState as conversationsInitialState } from '../reducer/conversations';
import { DEFAULT_COMPANY } from '../util';
import { getAllAgents } from './agents';
import { companiesById } from './companies';
import { getActiveConversationIds } from './conversations';
import { messagesByConversation } from './messages';

// state → user
export const getUser = (state) => state[USER];
export const getUserId = (state) => state[USER].userId;
export const getUserName = (state) => state[USER].name;
export const getUserPhoto = (state) => state[USER].photo;
export const getAuth = (state) => state[USER].auth;
export const getAWS = (state) => state[USER].aws;
export const getFirstTime = (state) => state[USER].firstTime;
export const getInstalled = (state) => state[USER].installed;

// state → string
export const getDefaultCompany = (state) => DEFAULT_COMPANY
  ?? state[USER].defaultCompany
  ?? 'twnel';

// state → bool
export const isConnected = (state) => !!state[USER].connected;
export const isOnline = (state) => !!state[USER].online;

const sessionUser = createSelector(
  getUser,
  pick(['userId', 'auth', 'aws', 'photo', 'name', 'defaultCompany']),
);

const sessionConversations = createSelector(
  getActiveConversationIds,
  conversationsInitialState,
);

const sessionCompanies = createSelector(
  companiesById,
  map(pick(['id', 'name', 'logo'])),
);

const sessionAgents = createSelector(
  getActiveConversationIds,
  getAllAgents,
  compose(
    map(({ byId, allIds }) => ({
      byId: map(pick(['id', 'name', 'photo']), byId),
      allIds,
    })),
    pick,
  ),
);

const sessionMessages = createSelector(
  getActiveConversationIds,
  messagesByConversation,
  compose(
    map(({ byId, allIds }) => {
      const ids = allIds.slice(-20);
      return {
        allIds: ids,
        byId: pick(ids, byId),
      };
    }),
    pick,
  ),
);

// state → session_data
export const getSessionData = createStructuredSelector({
  user: sessionUser,
  conversations: sessionConversations,
  companies: sessionCompanies,
  agents: sessionAgents,
  messages: sessionMessages,
});

// state → countryCode
export const getDefaultCountry = createSelector(
  getCountries,
  ({ clientCountry }) => clientCountry.code,
);
