import { createGlobalStyle } from 'styled-components';
import { IS_IFRAME } from '@twnel/web-components';
import { IS_IOS_SAFARI } from 'src/data/util';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${({ theme }) => (IS_IFRAME ? 'transparent !important' : theme.background)};
  }
  #react {
    width: 100vw;
    height: calc(100vh - env(safe-area-inset-bottom));
    overflow: hidden;
    ${IS_IOS_SAFARI ? `
      position: relative;
      height: calc(100vh - env(safe-area-inset-bottom) + 1px);
    ` : `
      position: fixed;
      height: -webkit-fill-available;
      height: fill-available;
      padding-bottom: env(safe-area-inset-bottom);
    `}
  }
  *:focus {
    outline: none;
  }
`;

export default GlobalStyle;
