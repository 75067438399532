import { compose, identity } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { withTwnelContext, IS_IFRAME } from '@twnel/web-components';
import { logTime } from 'src/data/util';
import GlobalStyle from 'src/ui/util/GlobalStyle';
import { withIframeToggle, withEventReporter } from 'src/ui/widget';

const enhancer = compose(
  withTwnelContext,
  IS_IFRAME ? withIframeToggle : withEventReporter,
);

const onMount = IS_IFRAME ? identity : () => {
  const { performance } = window || {};
  if (performance) {
    const timeSincePageLoad = performance.now();
    logTime('render', timeSincePageLoad);
  }
};

const App = ({ Body, defaultCompany }) => {
  React.useEffect(onMount, []);
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Switch>
        <Route exact path="/:id" component={Body} />
        <Redirect to={`/${defaultCompany}`} />
      </Switch>
    </BrowserRouter>
  );
};

App.propTypes = {
  Body: PropTypes.elementType.isRequired,
  defaultCompany: PropTypes.string.isRequired,
};

export default enhancer(App);
