import { createSelector } from 'reselect';
import { smartSelector, uncurrySelector } from '@twnel/web-components';
import { AGENTS } from '../namespace';

// state → agentsMap
export const getAllAgents = (state) => state[AGENTS];

// [selector | companyId] → state → agentsMap
export const getAgentsById = uncurrySelector(2, (companyId) => smartSelector(
  getAllAgents,
  companyId,
  (agents, company) => (agents[company] ? agents[company].byId : {}),
));

const getAgentsAllIds = (companyId) => smartSelector(
  getAllAgents,
  companyId,
  (agents, company) => (agents[company] ? agents[company].allIds : []),
);

// [selector | companyId] → state → agents
export const getAgents = uncurrySelector(2, (companyId) => createSelector(
  getAgentsById(companyId),
  getAgentsAllIds(companyId),
  (agents, ids) => ids.map((id) => agents[id]),
));

// [selector | companyId] → [selector | agentId] → state → agents
export const getAgent = uncurrySelector(3, (companyId) => (agentId) => smartSelector(
  getAgentsById(companyId),
  agentId,
  (agents, id) => agents[id],
));
