import React from 'react';
import isMobile from 'is-mobile';
import { useBlurEventReporter } from '../connect/useGetUnreadMessages';
import useSessionListener, { useSessionReporter } from '../connect/useSessionListener';
import useGoToPWA from '../connect/useGoToPWA';
import WidgetButton from './WidgetButton';
import { WidgetContextProvider } from './WidgetContext';

export const withEventReporter = (Component) => React.forwardRef((props, ref) => {
  useSessionReporter();
  useBlurEventReporter();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} ref={ref} />;
});

export default function withIframeToggle(Component) {
  return React.forwardRef((props, ref) => {
    const mobile = isMobile();
    useSessionListener({ active: mobile });

    const [collapse, setCollapse] = React.useState(true);
    const openWidget = React.useCallback(() => setCollapse(false), []);
    const closeWidget = React.useCallback(() => setCollapse(true), []);

    const goToPWA = useGoToPWA();
    return mobile || collapse
      ? <WidgetButton openWidget={mobile ? goToPWA : openWidget} />
      /* eslint-disable react/jsx-props-no-spreading */
      : (
        <WidgetContextProvider closeWidget={closeWidget}>
          <Component {...props} ref={ref} />
        </WidgetContextProvider>
      ); /* eslint-enable react/jsx-props-no-spreading */
  });
}
