import { createSelector } from 'reselect';
import { smartSelector, uncurrySelector } from '@twnel/web-components';
import { decodeId } from '@twnel/utils-js/lib/web';
import { CONVERSATIONS } from '../namespace';

const conversationsById = (state) => state[CONVERSATIONS].byId;
const conversationsIds = (state) => state[CONVERSATIONS].allIds;

// state → conversations
export const getAllConversations = createSelector(
  conversationsById,
  conversationsIds,
  (conversations, ids) => ids.map((id) => conversations[id]),
);

// [selector | conversationId] → state → conversation
export const getConversation = uncurrySelector(2, (conversationId) => smartSelector(
  conversationsById,
  conversationId,
  (conversations, id) => conversations[id],
));

export const getActiveConversationIds = (state) => state[CONVERSATIONS].activeIds;
export const activeConversationId = (state) => state[CONVERSATIONS].activeIds[0];

export const getUrlId = () => createSelector(
  (_, props) => props.match.params.id,
  (id) => decodeId(id) || id,
);
