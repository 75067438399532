import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isMobile from 'is-mobile';
import { refreshSession } from 'src/data/actions';
import { WIDGET } from 'src/data/namespace';
import { getAWS, getAuth } from 'src/data/selectors';
import { ENDPOINTS } from 'src/data/util';

const { PWA_ORIGIN } = ENDPOINTS;
const WIDGET_SESSION = `${WIDGET}/session`;

const updateSession = ({ aws, auth }) => ({
  type: WIDGET_SESSION,
  payload: { aws, auth },
});

export const useSessionReporter = () => {
  const aws = useSelector(getAWS);
  const auth = useSelector(getAuth);
  React.useEffect(() => {
    if (isMobile() && aws && auth) {
      window.opener?.postMessage(
        updateSession({ aws, auth }),
        PWA_ORIGIN,
      );
    }
  }, [aws, auth]);
};

export default function useSessionListener({ active }) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const messageListener = active ? ({ data } = {}) => {
      if (data?.type === WIDGET_SESSION) {
        dispatch(refreshSession({
          aws: data?.payload?.aws,
          auth: data?.payload?.auth,
        }));
      }
    } : undefined;
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [active, dispatch]);
}
