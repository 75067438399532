export const AGENTS = 'agents';
export const UPDATE_AGENTS = `${AGENTS}/update`;

export const COMPANIES = 'companies';
export const UPDATE_COMPANY = `${COMPANIES}/update`;

export const CONVERSATIONS = 'conversations';
export const UPDATE_CONVERSATIONS = `${CONVERSATIONS}/update`;
export const REGISTER_ACTIVE_CONVERSATION = `${CONVERSATIONS}/register_active`;

export const MESSAGES = 'messages';
export const UPDATE_MESSAGES = `${MESSAGES}/update`;
export const UPDATE_MESSAGES_INFO = `${MESSAGES}/update_info`;
export const DELETE_MESSAGES = `${MESSAGES}/delete`;

export const USER = 'user';
export const UPDATE_USER = `${USER}/update`;
export const LOGOUT_USER = `${USER}/logout`;
export const UPDATE_CONNECTION_STATE = `${USER}/connection`;

export const WIDGET = 'com.twnel.widget';
export const WIDGET_SIZE = `${WIDGET}/update_size`;
export const WIDGET_UPDATED = `${WIDGET}/updated`;
export const WIDGET_COMPANY = 'company';
