import { compose } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { withTwnelContext } from '@twnel/web-components';
import { getDefaultCompany } from 'src/data/selectors';
import GlobalStyle from 'src/ui/util/GlobalStyle';

const withLightShell = (Component) => React.forwardRef((props, ref) => {
  const defaultCompany = useSelector(getDefaultCompany);
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Switch>
        <Route exact path="/:id">
          <Component {...props} ref={ref} />
        </Route>
        <Redirect to={`/${defaultCompany}`} />
      </Switch>
    </BrowserRouter>
  ); /* eslint-enable react/jsx-props-no-spreading */
});

const enhancedShell = compose(withTwnelContext, withLightShell);
export default enhancedShell;
