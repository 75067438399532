import { dissoc, mergeDeepRight, uniq } from 'ramda';
import {
  CONVERSATIONS, LOGOUT_USER, UPDATE_CONVERSATIONS, REGISTER_ACTIVE_CONVERSATION,
} from '../namespace';

export const initialState = (activeIds = []) => ({
  byId: {},
  allIds: [],
  activeIds,
});

const updateConversations = ({ conversation }, state) => {
  const { byId, allIds } = state;
  return {
    byId: mergeDeepRight(byId, { [conversation.id]: dissoc('lastMessage', conversation) }),
    allIds: uniq([...allIds, conversation.id]),
  };
};

const reducer = (state = initialState(), action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CONVERSATIONS:
      return updateConversations(payload, state);
    case REGISTER_ACTIVE_CONVERSATION: {
      const { conversation } = payload;
      return {
        ...updateConversations(payload, state),
        activeIds: uniq([...state.activeIds, conversation.id]),
      };
    }
    case LOGOUT_USER:
      return initialState(state.activeIds);
    default:
      return state;
  }
};

export default { [CONVERSATIONS]: reducer };
