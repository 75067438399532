import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useRouteMatch } from 'react-router-dom';
import isMobile from 'is-mobile';
import { getUrlId, getMessages } from 'src/data/selectors';
import { WIDGET } from 'src/data/namespace';
import { ENDPOINTS } from 'src/data/util';

const { PWA_ORIGIN } = ENDPOINTS;
const BLUR_EVENT = `${WIDGET}/page_blur`;

const getUnreadMessages = (conversationId, since) => createSelector(
  getMessages(conversationId),
  (messages) => (since
    ? messages
      .filter(({ companyId, date }) => companyId && date > since)
      .length
    : 0),
);

export const useBlurEventReporter = () => {
  React.useEffect(() => {
    const blurListener = isMobile() ? () => {
      window.opener?.postMessage({ type: BLUR_EVENT }, PWA_ORIGIN);
    } : undefined;
    window.addEventListener('blur', blurListener);
    return () => {
      window.removeEventListener('blur', blurListener);
    };
  }, []);
};

export default function useGetUnreadMessages() {
  const [since, setSince] = React.useState(null);
  React.useEffect(() => {
    setSince(Date.now());
    const messageListener = ({ data } = {}) => {
      if (data?.type === BLUR_EVENT) {
        setSince(Date.now());
      }
    };
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  const match = useRouteMatch();
  const idSelector = React.useMemo(getUrlId, []);
  const conversationId = useSelector((state) => idSelector(state, { match }));

  const unreadSelector = React.useMemo(
    () => getUnreadMessages(conversationId, since),
    [conversationId, since],
  );
  return useSelector(unreadSelector);
}
