import { contains } from 'ramda';
import { newConversation, splitId } from '@twnel/utils-js/lib/web';
import { CONVERSATIONS, UPDATE_CONVERSATIONS, REGISTER_ACTIVE_CONVERSATION } from '../namespace';
import { getUserId, getConversation, getActiveConversationIds } from '../selectors';
import { ENDPOINTS } from '../util';

export const updateConversation = (conversation) => ({
  type: UPDATE_CONVERSATIONS,
  payload: { conversation },
});

const registerActive = (conversation) => ({
  type: REGISTER_ACTIVE_CONVERSATION,
  payload: { conversation },
});

export const registerActiveConversation = (conversationId) => (dispatch, getState) => {
  const activeIds = getActiveConversationIds(getState());
  let conversation = getConversation(conversationId, getState());
  if (contains(conversationId, activeIds) && conversation) {
    return;
  }

  if (!conversation) {
    const userId = getUserId(getState());
    conversation = newConversation({
      with: `${conversationId}@${ENDPOINTS.XMPP_HOST}`,
      owner: `${userId}@${ENDPOINTS.XMPP_HOST}`,
    });
  }
  dispatch(registerActive(conversation));
};

const updateTyping = ({ conversationId, agentId = true }) => (typing) => (dispatch, getState) => {
  const conversation = getConversation(conversationId, getState());
  if (conversation) {
    conversation.typing = typing ? agentId : null;
    dispatch(updateConversation(conversation));
  }
  return conversation;
};

export const onTypingEvent = (rawId) => (dispatch, getState, getContext) => {
  const { cacheStore } = getContext();
  const timeouts = cacheStore.get(`${CONVERSATIONS}/timeouts`);

  const { id: conversationId, agent } = splitId(rawId);

  const updateConversationTyping = updateTyping(conversationId, agent);
  if (dispatch(updateConversationTyping(true))) {
    const pendingTimeout = timeouts.get(conversationId);
    if (pendingTimeout) {
      clearTimeout(pendingTimeout);
    }

    const timeout = setTimeout(() => {
      timeouts.delete(conversationId);
      dispatch(updateConversationTyping(false));
    }, 3000);
    timeouts.set(conversationId, timeout);
  }
};
