import { map, prop } from 'ramda';
import { createSelector } from 'reselect';
import { isMetaMessage, sortMessages, CONTENT_TYPE } from '@twnel/utils-js/lib/web';
import { smartSelector, uncurrySelector } from '@twnel/web-components';
import { MESSAGES } from '../namespace';

const { IGNORE } = CONTENT_TYPE;

export const messagesByConversation = (state) => state[MESSAGES];

const getMessagesById = (conversationId) => smartSelector(
  messagesByConversation,
  conversationId,
  (messages, conversation) => (messages[conversation] ? messages[conversation].byId : {}),
);

const getMessagesAllIds = (conversationId) => smartSelector(
  messagesByConversation,
  conversationId,
  (messages, conversation) => (messages[conversation] ? messages[conversation].allIds : []),
);

const getAllMessages = uncurrySelector(2, (conversationId) => createSelector(
  getMessagesById(conversationId),
  getMessagesAllIds(conversationId),
  (messages, ids) => ids.map((id) => messages[id]),
));

// [selector | conversationId] → state → messages
export const getMessages = uncurrySelector(2, (conversationId) => createSelector(
  getAllMessages(conversationId),
  sortMessages,
));

// [selector | conversationId] → [selector | messageId] → state → message
export const getMessage = uncurrySelector(3, (conversationId) => (messageId) => smartSelector(
  getMessagesById(conversationId),
  messageId,
  (messages, id) => messages[id],
));

// state → messagesInfos
export const getAllMessagesInfo = createSelector(
  messagesByConversation,
  (messages) => map(prop('info'), messages),
);

// [selector | conversationId] → state → messagesInfos
export const getMessagesInfo = uncurrySelector(2, (conversationId) => smartSelector(
  messagesByConversation,
  conversationId,
  (messages, conversation) => (messages[conversation] ? messages[conversation].info : undefined),
));

// [selector | conversationId] → state → message
export const getTopMessage = uncurrySelector(2, (conversationId) => createSelector(
  getAllMessages(conversationId),
  (messages) => {
    const getLast = (index) => {
      const message = messages[index];
      return isMetaMessage(message) ? undefined : message;
    };
    let i = 0;
    let lastMessage = getLast(i);
    while (i < messages.length && !lastMessage) {
      i += 1;
      lastMessage = getLast(i);
    }
    return lastMessage;
  },
));

// [selector | conversationId] → state → message
export const getNewestMessage = uncurrySelector(2, (conversationId) => createSelector(
  getMessages(conversationId),
  (messages) => {
    const getFirst = (index) => {
      const message = messages[messages.length - (1 + index)];
      return message?.type === IGNORE ? undefined : message;
    };
    let i = 0;
    let firstMessage = getFirst(i);
    while (i < messages.length && !firstMessage) {
      i += 1;
      firstMessage = getFirst(i);
    }
    return firstMessage;
  },
));
