import React from 'react';
import { useSelector } from 'react-redux';
import { WIDGET } from 'src/data/namespace';
import { getDefaultCompany } from 'src/data/selectors';
import { ENDPOINTS } from 'src/data/util';

const WINDOW_NAME = `${WIDGET}/window`;
const { PWA_ORIGIN } = ENDPOINTS;

export default function useGoToPWA() {
  const defaultCompany = useSelector(getDefaultCompany);
  return React.useCallback(() => {
    const url = new URL(defaultCompany, PWA_ORIGIN);
    window.open(url.href, `${WINDOW_NAME}/${defaultCompany}`);
  }, [defaultCompany]);
}
