import { curry, curryN } from 'ramda';
import { isProduction } from './misc';

const analytics = (() => {
  const { gtag } = window || {};
  if (!gtag) {
    // eslint-disable-next-line no-console
    console.warn('Unable to locate google analytics instance.');
    return console.log; // eslint-disable-line no-console
  }
  return gtag;
})();

export const logEvent = curryN(2, (category, event, ...details) => {
  if (!isProduction()) {
    // eslint-disable-next-line no-console
    console.log(category, event, ...details);
    return;
  }
  analytics('event', event, { event_category: category });
});

export const logLogin = (referal) => {
  if (!isProduction()) {
    // eslint-disable-next-line no-console
    console.log('login', referal);
    return;
  }
  analytics('event', 'login', { method: referal });
};

export const logSignup = (referal) => {
  if (!isProduction()) {
    // eslint-disable-next-line no-console
    console.log('signup', referal);
    return;
  }
  analytics('event', 'sign_up', { method: referal });
};

export const logTime = curry((event, time) => {
  if (!isProduction()) {
    // eslint-disable-next-line no-console
    console.log('time', event, time);
    return;
  }
  analytics('event', 'timing_complete', {
    name: event,
    value: Math.round(time),
  });
});

export const logException = (error) => {
  if (!isProduction()) {
    // eslint-disable-next-line no-console
    console.error(error);
    return;
  }
  analytics('event', 'exception', { description: error.message });
};
