import { throwNetworkError } from '@twnel/web-components';
import { UPDATE_AGENTS } from '../namespace';

export const updateAgents = ({ companyId, update = [], remove = [] }) => ({
  type: UPDATE_AGENTS,
  payload: { companyId, update, remove },
});

export const fetchAgents = (companyId) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const companyMask = { id: companyId };
  const agents = await api.agents.get(companyMask)
    .catch(throwNetworkError);
  dispatch(updateAgents({ companyId, update: agents }));
  return agents;
};
