import { compose, assoc } from 'ramda';
import { isCompany } from '@twnel/utils-js/lib/web';
import { throwNetworkError } from '@twnel/web-components';
import { COMPANIES, UPDATE_COMPANY } from '../namespace';
import { getCompany, getAllCompanies } from '../selectors';
import { fetchAgents } from './agents';

export const updateCompany = (company) => ({
  type: UPDATE_COMPANY,
  payload: { company },
});

const fetchCompanyInfo = (companyId) => (dispatch, getState, getContext) => {
  const { api } = getContext();
  return api.companies.get({ id: companyId })
    .catch(throwNetworkError);
};

export const fetchCompany = (companyId) => (dispatch, getState, getContext) => {
  const { cacheStore } = getContext();
  const companyRequests = cacheStore.get(`${COMPANIES}/requests`);

  let promise = companyRequests.get(companyId);
  if (promise) {
    return promise;
  }

  const tasks = [
    dispatch(fetchCompanyInfo(companyId)),
    dispatch(fetchAgents(companyId)),
  ];
  promise = Promise.all(tasks)
    .then(([company]) => {
      dispatch(updateCompany({
        ...company,
        upToDate: true,
      }));
      companyRequests.delete(companyId);
      return company;
    })
    .catch((error) => {
      companyRequests.delete(companyId);
      throw error;
    });

  companyRequests.set(companyId, promise);
  return promise;
};

export const fetchCompanyIfNeeded = ({
  companyId,
  profileOnly = false,
} = {}) => async (dispatch, getState) => {
  const existingCompany = getCompany(companyId, getState());
  if (isCompany(existingCompany) && (profileOnly || existingCompany.upToDate)) {
    return existingCompany;
  }
  if (profileOnly) {
    const company = await dispatch(fetchCompanyInfo(companyId));
    dispatch(updateCompany(company));
    return company;
  }
  return dispatch(fetchCompany(companyId));
};

export const outdateCompanies = () => (dispatch, getState) => {
  const companies = getAllCompanies(getState());
  companies.forEach(compose(dispatch, updateCompany, assoc('upToDate', false)));
};
