import { merge, assoc } from 'ramda';
import { COMPANIES, UPDATE_COMPANY } from '../namespace';

const company = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_COMPANY:
      return merge(state, payload.company);
    default:
      return state;
  }
};

const reducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_COMPANY: {
      const { id } = payload.company;
      return assoc(id, company(state[id], action), state);
    }
    default:
      return state;
  }
};

export default { [COMPANIES]: reducer };
