import { values } from 'ramda';
import { createSelector } from 'reselect';
import { smartSelector, uncurrySelector } from '@twnel/web-components';
import { COMPANIES } from '../namespace';

// state → companiesMap
export const companiesById = (state) => state[COMPANIES];

// state → companies
export const getAllCompanies = createSelector(
  companiesById,
  values,
);

// [selector | companyId] → state → company
export const getCompany = uncurrySelector(2, (companyId) => smartSelector(
  companiesById,
  companyId,
  (companies, id) => companies[id],
));
