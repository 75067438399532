import {
  assoc, pluck, merge, fromPairs, uniq, mergeDeepLeft, mergeDeepRight, omit, without,
} from 'ramda';
import { pickExisting } from '@twnel/web-components';
import {
  MESSAGES, LOGOUT_USER, UPDATE_CONVERSATIONS, UPDATE_MESSAGES_INFO,
  UPDATE_MESSAGES, DELETE_MESSAGES,
} from '../namespace';

const messagesList = (state = {
  info: {},
  byId: {},
  allIds: [],
}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_MESSAGES_INFO:
      return mergeDeepRight(state, {
        info: {
          ...state.messagesInfo,
          ...pickExisting(['complete', 'upToDate', 'nextPageMarker'], payload),
        },
      });
    case UPDATE_MESSAGES: {
      let messageIds;
      const { messages, insert, leftMerge } = payload;
      const pairs = messages.map((message) => [message.id, message]);
      if (insert === 'replace') {
        messageIds = pluck(0, pairs);
      } else if (insert === 'left') {
        messageIds = [...pluck(0, pairs), ...state.allIds];
      } else {
        messageIds = [...state.allIds, ...pluck(0, pairs)];
      }
      return merge(state, {
        byId: (leftMerge ? mergeDeepLeft : mergeDeepRight)(
          state.byId,
          fromPairs(pairs),
        ),
        allIds: uniq(messageIds),
      });
    }
    case DELETE_MESSAGES: {
      const { messageIds } = payload;
      return {
        ...state,
        byId: omit(messageIds, state.byId),
        allIds: without(messageIds, state.allIds),
      };
    }
    default:
      return state;
  }
};

const updateWithLastMessage = (state, conversation) => {
  if (!conversation.lastMessage) {
    return state;
  }
  return messagesList(state[conversation.id], {
    type: UPDATE_MESSAGES,
    payload: {
      messages: [conversation.lastMessage],
      insert: 'right',
      leftMerge: true,
    },
  });
};

const reducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CONVERSATIONS: {
      const { conversation } = payload;
      return assoc(conversation.id, updateWithLastMessage(state, conversation), state);
    }
    case UPDATE_MESSAGES_INFO:
    case UPDATE_MESSAGES:
    case DELETE_MESSAGES: {
      const { conversationId: id } = payload;
      return assoc(id, messagesList(state[id], action), state);
    }
    case LOGOUT_USER:
      return {};
    default:
      return state;
  }
};

export default { [MESSAGES]: reducer };
