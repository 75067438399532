import React from 'react';
import { ErrorPageContext } from '@twnel/web-components';
import { logException } from 'src/data/util';

/**
 * This function is meant to be used alongside the `loader` function created by web-component's
 * `Loader` factory function. When used it will pass down the `logException` function throught
 * React's context, so that it may be used by the ErrorPage instance that wraps the components
 * created by the `loader` function.
 */
export default function withErrorLogger(Component) {
  return React.forwardRef((props, ref) => {
    const value = React.useMemo(() => ({ logger: logException }), []);
    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <ErrorPageContext.Provider value={value}>
        <Component {...props} ref={ref} />
      </ErrorPageContext.Provider>
    ); /* eslint-enable react/jsx-props-no-spreading */
  });
}
