import { identity } from 'ramda';
import React from 'react';
import { IS_IFRAME } from '@twnel/web-components';
import { generateUniqueSortableId } from '@twnel/utils-js/lib/web';
import { WIDGET_SIZE, WIDGET_UPDATED } from 'src/data/namespace';

const updateSize = ({ id, width, height }) => ({
  type: WIDGET_SIZE,
  payload: { id, width, height },
});

const useIframeSize = IS_IFRAME ? ({ width, height, onUpdate }) => {
  React.useLayoutEffect(() => {
    const eventId = generateUniqueSortableId();
    window.addEventListener('message', function updateListener({ data } = {}) {
      if (
        data?.type === WIDGET_UPDATED
        && data?.payload?.id === eventId
      ) {
        onUpdate();
        window.removeEventListener('message', updateListener);
      }
    });
    window.parent.postMessage(updateSize({
      id: eventId,
      width,
      height,
    }), '*');
  }, [width, height]);
} : identity;

export default useIframeSize;
