import to from 'await-to-js';
import { Endpoints, getApiHost } from '@twnel/utils-js/lib/web';
import { createImage } from '@twnel/web-components';
import { WIDGET_COMPANY } from '../namespace';

const { TWNEL_ENV, NODE_ENV, packageVersion } = process.env;
const environment = getApiHost() || TWNEL_ENV;

export const ENDPOINTS = {
  ENVIRONMENT: environment,
  ...Endpoints(environment),
};

export const DEFAULT_COMPANY = (() => {
  try {
    const url = new URL(window.location.href);
    return url?.searchParams?.get(WIDGET_COMPANY);
  } catch (error) {
    return undefined;
  }
})();

export const IS_IOS_SAFARI = /iPad|iPhone|iPod/.test(navigator.platform)
  && !window.navigator?.standalone;

export const isProduction = () => {
  const { twnel } = window || {};
  return twnel === 'debug' ? false : NODE_ENV === 'production';
};

export const getVersion = ({ prefix = false } = {}) => {
  let version = '';
  if (prefix) {
    version += 'twnel_app';
    if (environment === 'beta') {
      version += '_beta';
    }
    if (packageVersion) {
      const versionMatch = packageVersion.match(/^[0-9]+\.[0-9]+/);
      version += versionMatch ? `_${versionMatch[0].replace(/\W/g, '')}` : '';
    }
    return version;
  }
  if (environment === 'beta') {
    version += 'Beta ';
  }
  if (packageVersion) {
    version += `v${packageVersion}`;
  }
  return version;
};

export const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const readBarcode = async (file) => {
  const { BrowserMultiFormatReader, BarcodeFormat } = await import(
    /* webpackChunkName: "zxing" */
    '@zxing/library'
  );
  const codeReader = new BrowserMultiFormatReader();

  const objectURL = URL.createObjectURL(file);
  const [imgError, img] = await to(createImage(objectURL));
  if (imgError) {
    URL.revokeObjectURL(objectURL);
    throw imgError;
  }

  const [error, result] = await to(codeReader.decodeFromImage(img));
  if (error) {
    URL.revokeObjectURL(objectURL);
    throw error;
  }

  result.format = BarcodeFormat[result.format];
  URL.revokeObjectURL(objectURL);
  return result;
};

export const readBarcodeFromStream = async (stream) => {
  const { BrowserMultiFormatReader, BarcodeFormat } = await import(
    /* webpackChunkName: "zxing" */
    '@zxing/library'
  );
  const codeReader = new BrowserMultiFormatReader();
  const result = await codeReader.decodeOnceFromStream(stream);
  result.format = BarcodeFormat[result.format];
  return result;
};
