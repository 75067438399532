import { pick } from 'ramda';
import { pickExisting } from '@twnel/web-components';
import {
  USER, UPDATE_USER, LOGOUT_USER, UPDATE_CONNECTION_STATE,
} from '../namespace';

const reducer = (state = {}, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case UPDATE_USER:
      return {
        ...state,
        ...pickExisting(
          ['userId', 'auth', 'aws', 'photo', 'name', 'firstTime', 'installed', 'defaultCompany'],
          payload,
        ),
      };
    case UPDATE_CONNECTION_STATE:
      return {
        ...state,
        ...pickExisting(['connected', 'online'], payload),
      };
    case LOGOUT_USER:
      return pick(['userId', 'photo', 'name', 'defaultCompany'], state);
    default:
      return state;
  }
};

export default { [USER]: reducer };
