import { compose, uniq } from 'ramda';
import { Api, Http, encodeId } from '@twnel/utils-js/lib/web';
import Xmpp, { XMPP_EVENT } from '@twnel/utils-js/lib/xmpp';
import {
  pickExisting, networkError, throwNetworkError, catchNetworkError, getLocale,
} from '@twnel/web-components';
import {
  USER, UPDATE_USER, LOGOUT_USER, UPDATE_CONNECTION_STATE,
} from '../namespace';
import {
  getUser, getUserId, isConnected, isOnline, getDefaultCompany, getActiveConversationIds,
} from '../selectors';
import {
  urlBase64ToUint8Array, logEvent, logException, ENDPOINTS,
} from '../util';
import { outdateCompanies } from './companies';
import { registerActiveConversation, onTypingEvent } from './conversations';
import { outdateMessages, onXMPPMessage, onACKMessage } from './messages';

const {
  ONLINE, OFFLINE, AUTHENTICATION_ERROR, MESSAGE, ACK, TYPING,
} = XMPP_EVENT;
const { XMPP_HOST } = ENDPOINTS;

const newApi = compose(Api(ENDPOINTS), Http);

export const updateUser = ({
  userId, auth, aws, photo, name, firstTime, installed, defaultCompany,
} = {}) => ({
  type: UPDATE_USER,
  payload: {
    userId, auth, aws, photo, name, firstTime, installed, defaultCompany,
  },
});

const updateConnectionState = ({ connected, online }) => ({
  type: UPDATE_CONNECTION_STATE,
  payload: { connected, online },
});

const logoutUser = () => ({
  type: LOGOUT_USER,
});

const getURLConversation = () => {
  const { pathname } = new URL(window.location.href);
  return pathname?.split('/')?.[1];
};

const fetchContactInfo = (userId) => (dispatch, getState, getContext) => {
  const { api, cacheStore } = getContext();
  const userRequests = cacheStore.get(`${USER}/requests`);

  let promise = userRequests.get(userId);
  if (promise) {
    return promise;
  }

  promise = api.users.get(userId)
    .catch(throwNetworkError)
    .then((data = {}) => {
      userRequests.delete(userId);
      return data;
    });

  userRequests.set(userId, promise);
  return promise;
};

const updateUserInfo = () => (dispatch, getState) => {
  const userId = getUserId(getState());
  dispatch(fetchContactInfo(userId))
    .then(({ name, photo }) => {
      dispatch(updateUser({ name, photo }));
    })
    .catch(catchNetworkError(logException));
};

export const postUser = (user = {}) => async (dispatch, getState, getContext) => {
  const data = pickExisting(['name', 'photo'], user);
  if (!data.name && !data.photo) {
    return;
  }

  const update = { ...data };
  const file = data.photo instanceof File ? data.photo : null;
  if (file) {
    update.photo = URL.createObjectURL(file);
  }

  const { userId, name: oldName, photo: oldPhoto } = getUser(getState());
  dispatch(updateUser(update));

  const { api } = getContext();
  try {
    await api.users.put({ id: userId, ...data });
  } catch (error) {
    dispatch(updateUser({ name: oldName, photo: oldPhoto }));
    if (file) {
      URL.revokeObjectURL(update.photo);
    }
    throw networkError(error);
  }
};

const outdateData = () => (dispatch) => {
  dispatch(outdateCompanies());
  dispatch(outdateMessages());
};

export const disconnectXmpp = () => (dispatch, getState, getContext) => {
  const { xmpp, updateContext } = getContext();
  if (!xmpp) {
    return;
  }
  xmpp.disconnect();
  updateContext({ xmpp: null });
  dispatch(updateConnectionState({ connected: false }));
};

export const logout = () => (dispatch, getState, getContext) => {
  const { updateContext } = getContext();
  updateContext({ api: newApi() });
  dispatch(disconnectXmpp());
  dispatch(logoutUser());
};

const socketConnected = (online) => () => (dispatch, getState) => {
  if (isOnline(getState()) === online) {
    return;
  }
  const connected = isConnected(getState());
  if (connected && online) {
    dispatch(outdateData());
  }
  dispatch(updateConnectionState({
    connected: connected || online,
    online,
  }));
};

const connectXmpp = (password) => (dispatch, getState, getContext) => {
  const { xmpp: currentXmpp, updateContext } = getContext();
  if (currentXmpp) {
    return;
  }

  const xmpp = Xmpp({
    log: logEvent('xmpp'),
    config: {
      useStreamManagement: false,
    },
  });
  const bindDispatch = (listener) => compose(dispatch, listener);

  xmpp.on(ONLINE, bindDispatch(socketConnected(true)));
  xmpp.on(OFFLINE, bindDispatch(socketConnected(false)));
  xmpp.on(AUTHENTICATION_ERROR, bindDispatch(logout));
  xmpp.on(MESSAGE, bindDispatch(onXMPPMessage));
  xmpp.on(ACK, bindDispatch(onACKMessage));
  xmpp.on(TYPING, bindDispatch(onTypingEvent));

  const userId = getUserId(getState());
  xmpp.connect({ jid: userId, password, host: XMPP_HOST });
  updateContext({ xmpp });
};

const subscribePushService = () => async (dispatch, getState, getContext) => {
  const { api, notificationsManager, workerRegistration } = getContext();
  const permission = await notificationsManager.askPermission();
  if (permission && workerRegistration) {
    const subscription = await workerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array('BKBEzSk_94PJryZk7VyVdj9iy6hxKskj16JXcubuP9gnNYRrjDK8-1PaV7GV5gEN6tvI3P855tgNQ7ZTByxHIaQ'),
    });
    api.users.put({
      id: getUserId(getState()),
      browser: {
        agent: window.navigator.userAgent,
        locale: getLocale(),
        subscription,
      },
    });
  }
};

export const installApp = () => (dispatch, getState, getContext) => {
  const { install } = getContext();
  if (!install) {
    return;
  }
  dispatch(updateUser({ installed: true }));
  install();
};

export const installSetup = () => (dispatch, getState) => {
  const companyId = getActiveConversationIds(getState())[0];
  dispatch(updateUser({ defaultCompany: companyId }));
};

const setUp = ({ token, xmpp }) => (dispatch, getState, getContext) => {
  const { updateContext } = getContext();
  const api = newApi({ token, onTokenExpire: () => dispatch(logout()) });
  updateContext({ api });
  dispatch(outdateData());
  dispatch(updateUserInfo());
  dispatch(connectXmpp(xmpp));
};

const registerConversations = () => (dispatch, getState) => {
  const conversationIds = uniq([
    getURLConversation() || getDefaultCompany(getState()),
    ...getActiveConversationIds(getState()),
  ]);
  conversationIds.forEach(compose(dispatch, registerActiveConversation));
};

export const init = () => (dispatch, getState) => {
  const { aws, auth } = getUser(getState());
  if (aws && auth) {
    dispatch(registerConversations());
    dispatch(setUp(auth));
  } else {
    dispatch(logout());
  }
};

export const login = ({ userId, referal }) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  try {
    await api.users.login(userId, encodeId(referal));
  } catch (error) {
    throw networkError(500);
  }
  dispatch(updateUser({ userId }));
};

export const verifyLogin = ({ otp, referal }) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const userId = getUserId(getState());

  let sessionData;
  try {
    sessionData = await api.users.verify(userId, otp, encodeId(referal));
  } catch (error) {
    throw networkError(error === 400 ? 400 : 500);
  }

  if (!sessionData.s3 || !sessionData.xmpp_pass) {
    throw networkError(400); // Unauthorized, invalid otp.
  }

  const {
    first_time: firstTime,
    token,
    xmpp_pass: xmpp,
    s3,
  } = sessionData;

  const auth = { token, xmpp };
  dispatch(registerActiveConversation(referal));
  dispatch(setUp(auth));
  dispatch(updateUser({ auth, aws: s3, firstTime }));
  dispatch(subscribePushService());
};

export const refreshSession = ({ aws, auth }) => (dispatch, getState) => {
  const { aws: currentAWS, auth: currentAuth } = getUser(getState());
  if (currentAWS && currentAuth) {
    return;
  }

  if (aws && auth) {
    dispatch(registerConversations());
    dispatch(setUp(auth));
    dispatch(updateUser({ auth, aws }));
  }
};
