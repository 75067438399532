import {
  assoc, mergeDeepRight, uniq, omit, fromPairs, pluck,
} from 'ramda';
import { AGENTS, UPDATE_AGENTS, LOGOUT_USER } from '../namespace';

const agentsList = (state = {
  byId: {},
  allIds: [],
}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_AGENTS: {
      let result = state;
      const { update, remove } = payload;
      if (update) {
        const pairs = update.map((agent) => [agent.id, agent]);
        result = {
          byId: mergeDeepRight(result.byId, fromPairs(pairs)),
          allIds: uniq([...result.allIds, ...pluck(0, pairs)]),
        };
      }
      if (remove) {
        const agentIds = remove.map((agent) => agent.id);
        result = {
          byId: omit(agentIds, result.byId),
          allIds: result.allIds.filter((id) => agentIds.indexOf(id) < 0),
        };
      }
      return result;
    }
    default:
      return state;
  }
};

const reducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_AGENTS: {
      const { companyId } = payload;
      return assoc(companyId, agentsList(state[companyId], action), state);
    }
    case LOGOUT_USER:
      return {};
    default:
      return state;
  }
};

export default { [AGENTS]: reducer };
