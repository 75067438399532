import { prop, merge } from 'ramda';
import React from 'react';
import { render } from 'react-dom';
import {
  createStore, combineReducers, applyMiddleware, compose as reduxCompose,
} from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  Loader, CacheStore, ReducerRegistry, SessionMiddleware, ThunkMiddleware, NotificationsManager,
  TryCatchMiddleware, resourceReducer, loadTexts,
} from '@twnel/web-components';
import { init, updateUser, installSetup } from './data/actions';
import dataReducer from './data/reducer';
import { getSessionData } from './data/selectors';
import { isProduction, logException, getVersion } from './data/util';
import makeApp from './ui';

// Set window size if installed.
if (
  window.matchMedia('(display-mode: standalone)').matches
  || window.navigator.standalone === true
) {
  window.resizeTo(Math.round(790 / 1.6180), 790);
}

// Register reducers.
const reducerRegistry = ReducerRegistry();
reducerRegistry.register({ ...resourceReducer, ...dataReducer });
const storeReducer = combineReducers(reducerRegistry.getReducers());

// Middleware set up.
const prefix = getVersion({ prefix: true });
const session = SessionMiddleware({ prefix, selector: getSessionData });
const initialState = session.readState(prefix);

const thunk = ThunkMiddleware({
  reducerRegistry,
  cacheStore: CacheStore(),
  notificationsManager: NotificationsManager(),
});
const tryCatch = TryCatchMiddleware((error) => {
  logException(error);
  throw error;
});
const enhancer = applyMiddleware(tryCatch, thunk, session.middleware);

// Store set up
const compose = isProduction() ? reduxCompose : composeWithDevTools;
const store = createStore(storeReducer, initialState, compose(enhancer));
reducerRegistry.addListener((newReducer) => {
  store.replaceReducer(combineReducers(newReducer));
});
store.dispatch(init());

// Main app
const loader = Loader(store);
const App = makeApp({ loader });

// Initial render.
const app = React.createElement(App);
render(
  React.createElement(Provider, { store }, app),
  document.getElementById('react'),
);

// Load the texts file.
store.dispatch(loadTexts(async (locale) => {
  let loaders;
  switch (locale) {
    case 'es':
      loaders = [
        import(/* webpackChunkName: "esLocale" */ '@twnel/web-components/lib/es.json'),
        import(/* webpackChunkName: "esLocale" */ 'resources/localization/es.json'),
      ];
      break;
    case 'pt':
      loaders = [
        import(/* webpackChunkName: "ptLocale" */ '@twnel/web-components/lib/pt.json'),
        import(/* webpackChunkName: "ptLocale" */ 'resources/localization/pt.json'),
      ];
      break;
    default:
      return {};
  }
  const result = await Promise.all(loaders);
  return result.map(prop('default')).reduce(merge);
}));

// Register service worker.
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    const workerRegistration = await navigator.serviceWorker.register('/serviceWorker.js');
    store.dispatch((dispatch, getState, getContext) => {
      const { updateContext } = getContext();
      updateContext({ workerRegistration });
    });
  });
}

// Install prompt.
window.addEventListener('beforeinstallprompt', (event) => {
  const install = () => {
    event.prompt();
    return event.userChoice;
  };
  store.dispatch((dispatch, getState, getContext) => {
    const { updateContext } = getContext();
    updateContext({ install });
    store.dispatch(updateUser({ installed: false }));
  });
});
window.addEventListener('appinstalled', () => {
  store.dispatch(installSetup());
});
