import { mergeAll } from 'ramda';
import agents from './agents';
import companies from './companies';
import conversations from './conversations';
import messages from './messages';
import user from './user';

export default mergeAll([
  agents,
  companies,
  conversations,
  messages,
  user,
]);
