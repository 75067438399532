import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Badge } from '@twnel/web-components';
import useGetUnreadMessages from '../connect/useGetUnreadMessages';
import makeAppContainer from './makeAppContainer';
import withLightShell from './withLightShell';

const IFRAME_SIZE = {
  width: '4.5rem',
  height: '4.5rem',
};

const Container = makeAppContainer({
  extension: `
    background-color: transparent;
    box-shadow: none;
    overflow: visible;
  `,
  iframeSize: IFRAME_SIZE,
});

const Button = styled(Box).attrs({ depth: 2 })`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.65rem;
  background-color: ${({ theme }) => theme.brand};
  color: ${({ theme }) => theme.accentForeground};
  @media (hover: hover) {
    cursor: pointer;
    transition: transform 0.2s ease-out, background-color 0.2s ease-out;
    &:hover, &:active {
      transform: translateZ(0) scale(1.03);
      background-color: ${({ theme }) => theme.brandAccent};
    }
  }
`;

const WidgetButton = ({ openWidget }) => {
  const unreadCount = useGetUnreadMessages();
  return (
    <Container>
      <Button onClick={openWidget}>
        <i className="fas fa-comment" />
        {unreadCount ? (
          <Badge
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            {unreadCount}
          </Badge>
        ) : null}
      </Button>
    </Container>
  );
};

WidgetButton.propTypes = {
  openWidget: PropTypes.func.isRequired,
};

export default withLightShell(WidgetButton);
