import { identity } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';

const WidgetContext = React.createContext({
  closeWidget: identity,
  setDeferClose: identity,
});

const WidgetContextProvider = ({ children, closeWidget }) => {
  const deferClose = React.useRef(null);
  const deferredCloseWidget = React.useCallback(async () => {
    if (deferClose.current) {
      await deferClose.current();
    }
    closeWidget();
  }, [closeWidget]);

  const contexValue = React.useMemo(() => ({
    closeWidget: deferredCloseWidget,
    setDeferClose: (deferCallback) => {
      deferClose.current = deferCallback;
    },
  }), [deferredCloseWidget]);

  return (
    <WidgetContext.Provider value={contexValue}>
      {children}
    </WidgetContext.Provider>
  );
};

WidgetContextProvider.propTypes = {
  children: PropTypes.node,
  closeWidget: PropTypes.func.isRequired,
};

WidgetContextProvider.defaultProps = {
  children: null,
};

export { WidgetContextProvider };

export const useCloseWidget = () => {
  const { closeWidget } = React.useContext(WidgetContext);
  return closeWidget;
};

export const useSetDeferCloseWidget = () => {
  const { setDeferClose } = React.useContext(WidgetContext);
  return setDeferClose;
};
