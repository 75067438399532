import { identity, reduce, reduced } from 'ramda';
import { formatPhoneNumber } from '@twnel/web-components';
import {
  CONTENT_TYPE, encodeId, isCompany, getMessageContent, getCompanyChatbots,
} from '@twnel/utils-js/lib/web';

const {
  AUDIO, IMAGE, VIDEO, LOCATION, PDF,
} = CONTENT_TYPE;

export const conversationAvatar = (...args) => {
  const obj = reduce(
    (acc, value) => (acc ? reduced(acc) : value),
    false,
    args,
  );
  if (isCompany(obj)) {
    const { id, name, logo } = obj;
    return {
      id,
      name: name || id,
      image: logo,
    };
  }

  switch (typeof obj) {
    case 'object': {
      const { id, name, photo } = obj;
      return {
        id,
        name: name || formatPhoneNumber(id),
        image: photo,
      };
    }
    case 'string':
      return {
        id: obj,
        name: formatPhoneNumber(obj),
      };
    default:
      throw new Error('Unexpected object passed to conversationAvatar', obj);
  }
};

export const messageAvatar = (
  texts = identity,
  user = {},
  company = {},
  agents = {},
  message,
) => {
  const { author, companyId, info } = message;
  if (!companyId) {
    return {
      id: user.userId,
      name: user.name || formatPhoneNumber(user.userId),
      image: user.photo,
    };
  }
  if (author === companyId) {
    return {
      id: companyId,
      name: company.name ? company.name : companyId,
      image: company.logo,
      avatarType: 'company',
    };
  }
  const agent = agents[author] || {};
  const result = {
    id: author,
    image: agent.photo,
    companyName: company.name ? company.name : companyId,
  };
  if (info.chatbot_id) {
    const chatbots = getCompanyChatbots(company);
    if (chatbots[info.chatbot_id]) {
      result.name = chatbots[info.chatbot_id].message;
    } else {
      const hashId = encodeId(author).slice(-4).toUpperCase();
      result.name = `${texts('Bot')} • ${hashId}`;
    }
    result.avatarType = 'bot';
  } else {
    if (agent.name) {
      result.name = agent.name;
    } else {
      const hashId = encodeId(author).slice(-4).toUpperCase();
      result.name = `${texts('Agent')} • ${hashId}`;
    }
    result.avatarType = 'agent';
  }
  return result;
};

const messageDescription = (texts = identity, author, message) => {
  let description;
  switch (message.type) {
    case AUDIO:
      description = `${author} ${texts('sent an audio file')}`;
      break;
    case IMAGE:
      description = `${author} ${texts('sent an image')}`;
      break;
    case VIDEO:
      description = `${author} ${texts('sent a video')}`;
      break;
    case LOCATION:
      description = `${author} ${texts('shared a location')}`;
      break;
    case PDF:
      description = `${author} ${texts('sent a PDF file')}`;
      break;
    default: {
      const { content } = getMessageContent(message);
      description = `${author}: ${content}`;
    }
  }
  return description;
};

export const messageNotification = ({
  texts, user, company, agents, conversation, message,
}) => {
  const { name: title, image: conversationImage } = conversationAvatar(company, conversation);
  const { name, image } = messageAvatar(texts, user, company, agents, message);
  const body = messageDescription(texts, name, message);
  return {
    tag: message.id,
    title,
    body,
    image: image || conversationImage,
  };
};
