import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, IS_IFRAME } from '@twnel/web-components';
import useIframeSize from '../connect/useIframeSize';
import { useSetDeferCloseWidget } from './WidgetContext';

const IFRAME_OFFSET = 16.0;

export default function makeAppContainer({ extension, iframeSize } = {}) {
  if (!IS_IFRAME) {
    return extension ? styled.div`
      width: 100%;
      height: 100%;
      ${extension}
    ` : React.Fragment;
  }

  const Container = styled(Box).attrs({
    depth: 2,
  })`
    position: relative;
    width: calc(100% - ${IFRAME_OFFSET}px);
    height: calc(100% - ${IFRAME_OFFSET}px);
    overflow: hidden;
    margin: ${IFRAME_OFFSET / 2}px;
    border-radius: 0.4rem;
    background-color: ${({ theme }) => theme.background};
    ${extension}
  `;

  if (!iframeSize) {
    return Container;
  }

  const DynamicContainer = ({ children }) => {
    const { width, height } = iframeSize;
    const [showContent, setShowContent] = React.useState(false);
    useIframeSize({
      width,
      height,
      onUpdate: () => setTimeout(() => setShowContent(true), 40),
    });

    const setDeferClose = useSetDeferCloseWidget();
    React.useEffect(() => {
      setDeferClose(() => {
        setShowContent(false);
        return new Promise((resolve) => {
          setTimeout(resolve, 200);
        });
      });
      return () => {
        setDeferClose(null);
      };
    }, [setDeferClose]);

    return (
      <Container
        style={{
          transition: 'opacity 0.16s ease-out',
          opacity: showContent ? 1 : 0,
        }}
      >
        {children}
      </Container>
    );
  };

  DynamicContainer.propTypes = {
    children: PropTypes.node,
  };

  DynamicContainer.defaultProps = {
    children: null,
  };

  return DynamicContainer;
}
