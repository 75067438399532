import { compose } from 'ramda';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { strictEqual } from '@twnel/web-components';
import {
  getAWS, getAuth, getFirstTime, getDefaultCompany,
} from 'src/data/selectors';
import withErrorLogger from 'src/ui/util/withErrorLogger';
import App from './App';

const getSession = () => createSelector(
  getAWS,
  getAuth,
  getFirstTime,
  (aws, auth, firstTime) => !!(aws && auth && !firstTime),
);

export default function makeApp({ loader }) {
  const enhancedLoader = compose(withErrorLogger, loader);

  const Login = enhancedLoader(() => import(
    /* webpackChunkName: "login" */ 'src/ui/login'
  ));

  const Chat = enhancedLoader(() => import(
    /* webpackChunkName: "chat" */ 'src/ui/chat'
  ));

  const bodySelector = createSelector(
    getSession(),
    (session) => (session ? Chat : Login),
  );

  const mapStateToProps = createStructuredSelector({
    Body: bodySelector,
    defaultCompany: getDefaultCompany,
  });

  return connect(mapStateToProps, null, null, { areStatePropsEqual: strictEqual })(App);
}
