import {
  sort, head, merge, filter, prop, assoc, keys,
} from 'ramda';
import {
  splitId, parseApiMessage, messagesCompare, newMessage, cleanMessageObject, getMessageContent,
  CONVERSATION_TYPE, CONTENT_TYPE, DELIVERY_STATE,
} from '@twnel/utils-js/lib/web';
import { uploadS3 } from '@twnel/utils-js/lib/aws';
import {
  ImageLoader, getBestEffortLocation, parseLocation, fileFromDataURL, throwNetworkError, getTexts,
} from '@twnel/web-components';
import { UPDATE_MESSAGES, DELETE_MESSAGES, UPDATE_MESSAGES_INFO } from '../namespace';
import {
  getUser, getUserId, getCompany, getAgentsById, getConversation, getTopMessage, getMessage,
  getMessagesInfo, getAllMessagesInfo,
} from '../selectors';
import { ENDPOINTS, messageNotification } from '../util';

const { BUSINESS, AGENT, GROUP } = CONVERSATION_TYPE;
const { LOCATION, SESSION, IGNORE } = CONTENT_TYPE;
const {
  NOT_SENT, SENT, NOTIFIED, ARRIVED, READ,
} = DELIVERY_STATE;

const MESSAGE_RETRY_INTERVAL = 16000;

const newMessagesInfo = ({ type }) => ({
  complete: false,
  upToDate: false,
  nextPageMarker: type === GROUP ? Date.now() : 1,
});

const updateMessages = (conversationId, messages, insert) => ({
  type: UPDATE_MESSAGES,
  payload: { conversationId, messages, insert },
});

const deleteMessages = (conversationId, messageIds) => ({
  type: DELETE_MESSAGES,
  payload: { conversationId, messageIds },
});

const updateMessagesInfo = (conversationId, { complete, upToDate, nextPageMarker }) => ({
  type: UPDATE_MESSAGES_INFO,
  payload: {
    conversationId, complete, upToDate, nextPageMarker,
  },
});

const loadPage = ({ conversation, pageMarker }) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const result = await api.messages.get({ conversation, pageMarker })
    .catch(throwNetworkError);

  const { messages, complete } = result;
  const sortedMessages = sort(messagesCompare, messages);
  const oldestMessage = head(sortedMessages);
  const lastMessage = getTopMessage(conversation.id, getState());
  const allNew = lastMessage && oldestMessage
    && oldestMessage.created_at > lastMessage.created_at;

  let nextPageMarker;
  if (conversation.type === GROUP) {
    nextPageMarker = oldestMessage ? oldestMessage.created_at : pageMarker;
  } else {
    nextPageMarker = pageMarker + 1;
  }

  return {
    messages: sortedMessages,
    complete,
    nextPageMarker,
    allNew,
  };
};

export const loadMessagesPage = (conversationId) => async (dispatch, getState) => {
  const conversation = getConversation(conversationId, getState());
  if (!conversation) {
    throw new Error(`Conversation not in memory: ${conversationId}`);
  }

  const info = getMessagesInfo(conversationId, getState());
  if (info && info.complete) {
    return;
  }

  const { nextPageMarker: pageMarker } = info || newMessagesInfo(conversation);
  const result = await dispatch(loadPage({ conversation, pageMarker }));
  const { messages, complete, nextPageMarker } = result;
  dispatch(updateMessagesInfo(conversationId, {
    complete,
    nextPageMarker,
    upToDate: info ? info.upToDate : true,
  }));
  dispatch(updateMessages(conversationId, messages, info ? 'left' : 'replace'));
};

export const refreshMessages = (conversationId) => async (dispatch, getState) => {
  const conversation = getConversation(conversationId, getState());
  if (!conversation) {
    throw new Error(`Conversation not in memory: ${conversationId}`);
  }

  const info = getMessagesInfo(conversationId, getState());
  if (info && info.upToDate) {
    return;
  }

  const load = async (pageMarker, list = []) => {
    const result = await dispatch(loadPage({ conversation, pageMarker }));
    const { messages, allNew, nextPageMarker } = result;
    const messagesList = [...messages, ...list];
    return allNew ? load(nextPageMarker, messagesList) : messagesList;
  };

  const { nextPageMarker: firstPage } = newMessagesInfo(conversation);
  const messages = await load(firstPage);
  dispatch(updateMessagesInfo(conversationId, merge(info, { upToDate: true })));
  dispatch(updateMessages(conversationId, messages, 'right'));
};

export const outdateMessages = () => (dispatch, getState) => {
  const conversationIds = keys(filter(
    prop('upToDate'),
    getAllMessagesInfo(getState()),
  ));
  conversationIds.forEach((id) => dispatch(updateMessagesInfo(id, { upToDate: false })));
};

const conversationIdFromMessage = ({ groupId, from, to }, state) => {
  let conversationId;
  if (groupId) {
    conversationId = groupId;
  } else {
    const userId = getUserId(state);
    const { id: idFrom } = splitId(from);
    const { id: idTo } = splitId(to);
    if (idTo === userId) {
      conversationId = idFrom;
    } else if (idFrom === userId) {
      conversationId = idTo;
    }
  }
  return conversationId;
};

export const onXMPPMessage = (rawMessage = {}) => (dispatch, getState, getContext) => {
  const message = parseApiMessage(rawMessage);
  if (!message || message.type === IGNORE) {
    return;
  }

  const conversationId = conversationIdFromMessage(message, getState());
  const conversation = getConversation(conversationId, getState());
  if (!conversation) {
    return;
  }

  // If message was received while offline use the delay stamp, else use the current time. If we
  // assume that dispatch and delivery times are almost identical when online, using the current
  // time should be safe.
  if (rawMessage.delay?.timestamp) {
    message.date = rawMessage.delay.timestamp.getTime();
  } else {
    message.date = Date.now();
  }

  const isNew = !getMessage(conversationId, message.id, getState());
  dispatch(updateMessages(conversationId, [message]));
  if (!isNew) {
    return;
  }

  const { type, author, companyId: origin } = message;
  const userId = getUserId(getState());
  if ((origin || author !== userId) && type !== SESSION) {
    const notification = {
      texts: getTexts(getState()),
      user: getUser(getState()),
      conversation,
      message,
    };

    let companyId;
    if (conversation.type === BUSINESS || conversation.type === AGENT) {
      const { id } = splitId(conversation.with);
      companyId = id;
    } else if (conversation.type === GROUP) {
      companyId = conversation.company;
    }

    if (companyId) {
      notification.company = getCompany(companyId, getState());
      notification.agents = getAgentsById(companyId, getState());
    }

    const { notificationsManager } = getContext();
    notificationsManager.sendNotification(messageNotification(notification));
  }
};

const deliveryStateFromEvent = (event = '') => {
  if (event === 'offline') {
    return NOTIFIED;
  }
  if (event === 'delivered') {
    return ARRIVED;
  }
  if (event === 'displayed') {
    return READ;
  }
  return undefined;
};

export const onACKMessage = ({
  id, to, from, event,
} = {}) => (dispatch, getState) => {
  const deliveryState = deliveryStateFromEvent(event);
  if (!id || !to || !from || !deliveryState) {
    return;
  }

  const conversationId = conversationIdFromMessage({ to, from }, getState());
  const message = getMessage(conversationId, id, getState());
  if (!message || message.deliveryState >= deliveryState) {
    return;
  }

  const update = assoc('deliveryState', deliveryState, message);
  dispatch(updateMessages(conversationId, [update]));
};

const uploadMedia = (conversationId, message) => async (dispatch, getState) => {
  if (message.file && !message.media_url) {
    const { aws } = getUser(getState());
    const { url } = await uploadS3({ aws, key: message.id, file: message.file });
    const updatedMessage = {
      ...getMessage(conversationId, message.id, getState()),
      media_url: url,
    };
    dispatch(updateMessages(conversationId, [updatedMessage]));
    return updatedMessage;
  }
  return message;
};

const processGeolocation = (() => {
  const loadImage = ImageLoader();
  return (conversationId, message) => async (dispatch, getState) => {
    if (
      message.type === LOCATION
      && message.info.data.address === undefined
      && message.info.data.image === undefined
    ) {
      const { content } = getMessageContent(message);
      const { latitude, longitude } = content;
      const data = await parseLocation({ latitude, longitude });

      let image;
      if (data?.url) {
        const { aws } = getUser(getState());
        const dataURL = await loadImage(data.url);
        const { key } = await uploadS3({
          aws,
          key: message.id,
          file: fileFromDataURL(dataURL),
        });
        image = key;
      }

      const existingMessage = getMessage(conversationId, message.id, getState());
      const updatedMessage = {
        ...existingMessage,
        info: {
          ...existingMessage.info,
          data: {
            ...existingMessage.info.data,
            address: data?.address ?? null,
            image: image ?? null,
          },
        },
      };
      dispatch(updateMessages(conversationId, [updatedMessage]));
      return updatedMessage;
    }
    return message;
  };
})();

const attachGeotag = (conversationId, message) => async (dispatch, getState) => {
  if (message.info.output?.location === true) {
    const location = await getBestEffortLocation();
    const existingMessage = getMessage(conversationId, message.id, getState());
    const updatedMessage = {
      ...existingMessage,
      info: {
        ...existingMessage.info,
        output: { ...existingMessage.info.output, location },
      },
    };
    dispatch(updateMessages(conversationId, [updatedMessage]));
    return updatedMessage;
  }
  return message;
};

const dispatchMessage = (conversationId, message) => async (dispatch, getState, getContext) => {
  const { xmpp } = getContext();
  let result = await dispatch(uploadMedia(conversationId, message));
  result = await dispatch(processGeolocation(conversationId, result));
  result = await dispatch(attachGeotag(conversationId, result));
  xmpp.sendMessage(cleanMessageObject(result));
};

const delay = (milliseconds) => new Promise((done) => setTimeout(done, milliseconds));

const queueMessage = (
  conversationId,
  messageId,
  autoRetry = false,
) => async (dispatch, getState, getContext) => {
  const { xmpp } = getContext();
  if (!xmpp) {
    throw new Error('XMPP client has not been initialized.');
  }

  const messageGetter = () => getMessage(
    conversationId,
    messageId,
    getState(),
  );

  const setDeliveryState = (deliveryState) => {
    const message = messageGetter();
    if (message && message.deliveryState !== deliveryState) {
      dispatch(updateMessages(conversationId, [
        assoc('deliveryState', deliveryState, message),
      ]));
    }
  };
  setDeliveryState(SENT);

  const deliveryAttempt = () => {
    const message = messageGetter();
    if (message && message.deliveryState <= SENT) {
      dispatch(dispatchMessage(conversationId, message));
    }
  };
  deliveryAttempt();

  const stamp = Date.now();
  const sent = await (async function queueLoop() {
    await delay(MESSAGE_RETRY_INTERVAL);
    const message = messageGetter();
    if (!message || message.deliveryState > SENT) {
      return true;
    }
    if (Date.now() - stamp > MESSAGE_RETRY_INTERVAL * 2.5) {
      return false;
    }
    if (autoRetry) {
      deliveryAttempt();
    }
    return queueLoop();
  }());

  if (!sent) {
    setDeliveryState(NOT_SENT);
  }
};

const createMessage = (conversationId, content = {}) => (dispatch, getState) => {
  if (!content.text && !content.file && !content.location && !content.session) {
    return undefined;
  }

  const userId = getUserId(getState());
  const message = newMessage({
    ...content,
    author: userId,
    host: ENDPOINTS.XMPP_HOST,
    to: conversationId,
  });

  dispatch(updateMessages(conversationId, [message]));
  return message;
};

/**
 * Send a message, new or existing
 * @param content: { id, text, file, location, session }
 */
export const sendMessage = (conversationId, content) => (dispatch, getState) => {
  const conversation = getConversation(conversationId, getState());
  if (!conversation) {
    return;
  }

  const messageId = content?.id
    || dispatch(createMessage(conversationId, content))?.id;
  if (!messageId) {
    return;
  }

  dispatch(queueMessage(conversationId, messageId, true));
};

export const deleteMessage = (conversationId, messageId) => (dispatch) => {
  dispatch(deleteMessages(conversationId, [messageId]));
};
